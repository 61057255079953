@import "src/Component/mixin";

.wrapper {
  margin-right: 2rem;

  .icon_avatar {
    cursor: pointer;
  }
}


.account {
  //position: absolute;
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);

  width: 18.75rem;
  right: 1.875rem;
  top: 2.375rem;

  hr {
    margin: .75rem 1.25rem;
    border: none;
    border-top: 1px solid var(--bg-hr);
  }

  ul {
    margin: 0 1.25rem;
    padding-bottom: .75rem;

    li {
      padding: .625rem .75rem;
      cursor: pointer;
      font-weight: 500;
      font-size: 0.935rem;
      line-height: 1.25rem;
      color: var(--color-li-up2022-7);

      transition: all .3s ease;
      overflow: hidden;
      border: none;
    }

    li:hover {
      background: #F2F9FD;
      color: var(--color-package-up2022-7);
      border-radius: var(--border-radius-component);
    }

    li:active {
      color: var(--white-color);
      background: var(--button-color);
    }

    .log_out {
      margin-top: 1.125rem;
    }

    .log_out:before {
      content: "";
      width: 16.25rem;
      height: .125rem;
      border-top: 1px solid var(--bg-hr);
      position: absolute;
      bottom: 3.75rem;
      left: 1.3125rem;
    }
  }

  .group {
    display: flex;
    padding: 1.5rem 1.25rem;

    img {
      width: 3.5rem;
      height: 3.5rem;
      margin: .2rem 1.125rem 0 0;
    }

    .inner {
      .name {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--color-tilte-up2022-7);

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 11rem;
        height: 1.2em;
        white-space: nowrap;
      }

      .email {
        font-weight: 400;
        font-size: .9375rem;
        line-height: 1.25rem;
        color: var(--color-subtilte-up2022-7);
        margin-bottom: .25rem;

        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 11rem;
        height: 1.2em;
        white-space: nowrap;
      }

      .package {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.25rem;
        color: var(--color-package-up2022-7);
        padding: 0 .625rem;
        border-radius: var(--border-radius-component);
        background: rgba(43, 184, 169, 0.1);
        width: fit-content;
      }
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(18.75rem);
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(18.75rem);
  }
}

.box_modal-account {
  .dismiss {
    @include desktop_large_max {
      top: 3.54rem;
      padding: 0.3rem;
    }
    @include desktop_width_1366 {
      top: 3.5rem;
      right:37.5rem;
      padding: 0.1rem 0.3rem;
    }
    @include desktop_width_1280 {
      top: 3.5rem;
      right: 37.5rem;
      padding: 0.1rem 0.3rem;
    }

    width: 1.5rem;
    height: 1.5rem;
    animation: fadeInRight .3s ease-in-out;
    position: fixed;
    top: 3.5rem;
    right: 37.5rem;
    cursor: pointer;
    background: rgba(124, 142, 160, 1);
    padding: 0.2rem .3rem;

    svg {
      height: 1rem;
      width: 1rem;
    }


  }

  .general {
    @include desktop_large_max {
      top: 3.54rem;
    }
    @include desktop_width_1366 {
      top: 3.5rem;

    }
    @include desktop_width_1280 {
      top: 3.5rem;
    }
    position: relative;
    animation: fadeInRight .3s ease-in-out;
    background: white;
    position: absolute;
    top: 3.47rem;
    right: 0;

    width: 38.25rem;
    height: 100%;
    overflow-y: scroll;

    -webkit-transition: .3s;
    transition: .3s;

    padding-bottom: 3.125rem;

    .bgp {
      height: 6.0625rem;
      width: 612px;
      object-fit: cover;
    }

    .grp_avatar {
      @include desktop_width_1280{
        left: 16rem;
      }
      @include desktop_width_1366{
        left: 16rem;
      }
      width: 6rem;
      height: 6rem;
      border: .1875px solid var(--white-color);
      border-radius: 50%;
      background: #F9F9FB;

      position: absolute;
      top: 2.625rem;
      left: 16rem;
      //box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
      cursor: pointer;
      transition: .5s;

      .update_image {
        width: 6rem;
        height: 6rem;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        margin-bottom: 16px;
        opacity: 0;
        position: absolute;
        top: -2px;
        left: -2px;
        cursor: pointer;
        z-index: 4;
      }

      .hover_image {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 96%;
        height: 97%;
        border-radius: 50%;
        background: #001f3ead;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon-camera{
        top: 70px;
        right:0;
        @media screen and (max-width: 1366px) {
          top: 51px;
          right: -8px;
        }
      }

      .avatar {
        width: 6rem;
        height: 6rem;
      }

      span:before {
        content: "";
        width: 1rem;
        height: 1rem;
        //background: var(--plum-color);
        position: absolute;
        bottom: 0;
        right: 7px;
        border-radius: 50%;
      }
    }

    .content {
      margin-top: 3.25rem;
      text-align: center;
      height: 3rem;

      .content_txt {
        width: 100% !important;
      }

      .info {
        .name {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #222222;
          margin-bottom: 8px;

          text-overflow: ellipsis;
          overflow: hidden;
          height: 1.2em;
          white-space: nowrap;
          max-width: 31.25rem;
        }

        .package {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: #1E9A98;
          display: flex;

          padding: 4px 10px;
          background: rgba(43, 184, 169, 0.1);
          width: fit-content;
          margin: 0 auto;
          border-radius: var(--border-radius-component);
          margin-bottom: 8px;

          svg {
            margin: .0625rem .3125rem;
          }
        }

        .duration {
          position: relative;
          display: flex;
          justify-content: center;

          a {
            margin-right: 8px;
            cursor: pointer;
            color: var(--color-link-up2022-7);
          }

          svg {
            margin-top: 8px;

            path {
              fill: var(--color-link-up2022-7);
            }
          }

          .list {
            position: absolute;
            left: 10.5rem;
            z-index: 2;
            background: var(--white-color);
            border-radius: var(--border-radius);
            padding: 12px 10px;
            box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
            top: 24px;

            a {
              padding: 12px 10px;
              text-align: left;
              display: block;
              color: var(--color-li-up2022-7);
              margin: 0;
            }

            a:hover {
              background: #F2F9FD;
              border-radius: var(--border-radius-component);
              color: var(--color-hover-package-up2022-7);
            }
          }
        }
      }
    }

    &.hide {
      display: none;
    }

  }
}

.grp_time {
  color: var(--white-color);
  margin: .5rem .75rem;

  p {
    font-weight: 500;
    font-size: .8125rem;
    line-height: 1rem;
    margin-bottom: .5rem;
  }
}

.modal_custom {
  animation: fadeInLeft .3s ease-in-out;
  height: 100%;
}

.box_confirm {
  background: white;
  margin: 20rem auto 0 auto;
  padding: 24px;
  line-height: 140%;

  width: 480px !important;
  max-height: 196px !important;
  border-radius: var(--border-radius);
  top: 0rem !important;
  left: 42rem !important;
  .txt_title {
    margin-bottom: 1.5rem;
    line-height: 140% !important;
  }

  .grp_btn {
    margin-top: 1.5rem;
    text-align: right;

    button {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      width: 110px;
      height: 32px;
      cursor: pointer;
      padding: 6px 24px;
      border-radius: var(--border-radius-component);
      border: 1px solid #edf0f6;
    }

    .dismiss {
      background: var(--white-color);
      margin-right: .75rem;

      &:hover {
        border: 1px solid var(--color-package-up2022-7);
      }
    }

    .save {
      color: var(--white-color);
      background: var(--bg-checked-radio);
      background: var(--color-package-up2022-7);
      margin-right: 0;

      &:hover {
        background: var(--color-hover-package-up2022-7);
        color: var(--white-color);
      }
    }
  }
}

/* Reponsive */
@media (max-width: 1366px) {
  html {
    font-size: .8rem;
  }
}

//@include