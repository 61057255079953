.tab-notification {
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    max-height: 96px;
    padding: 8px 0;
    &:hover{
      background: #EFF3FB;
    }
  }

  &__icon {
    width: 44px;
    height: 44px;
    margin-right: 12px;
  }

  &__info {
    width: 376px;
    display: grid;
    text-align: inherit;
    .tooltipV2 {
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tooltip-title{
      display: -webkit-box;
      height: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }



  &__body {
    overflow: scroll;
    // height: calc(68vh - 112px);
  }

  &__empty {
    height: 284px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;

    font-weight: 600;
    font-size: 14px;
    color: #7C88A6;
  }
}