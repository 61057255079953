.right-sight-popup {
  &__header {
    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
      color: #191D32;
      height: 28px;
    }

    &-sub-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: #191D32;
      margin-bottom: 32px;
    }
  }

  &__box {
    &--dismiss {
      width: 1.5rem;
      height: 1.5rem;
      animation: fadeInRight .3s ease-in-out;
      position: fixed;
      top: 3.5rem;
      right: 31.25rem;
      cursor: pointer;
      background: rgba(124, 142, 160, 1);
      padding: 0.2rem .3rem;

      svg {
        height: 1rem;
        width: 1rem;
      }

      &-full-height {
        top: 0;
      }
    }

    &-close {
      animation: fadeInLeft .3s ease-in-out;
      height: 100%;
    }
  }

  &__wrapper {
    animation: fadeInRight .3s ease-in-out;
    background: white;
    position: absolute;
    top: 3.5rem;
    right: 0;

    width: 31.25rem;
    height: 100vh;
    overflow-y: scroll;
    padding: 1.5rem;

    -webkit-transition: .3s;
    transition: .3s;
    &-full-height {
      top: 0;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    background: var(--white-color);
    width: 31.25rem;
    height: 4rem;
    text-align: end;

    hr {
      border: none;
      border-top: 1px solid var(--background-border);
    }

    &--group {
      margin-right: 24px;
    }

    button {
      font-size: 15px;
      line-height: 140%;
      margin-top: .875rem;
      max-height: 36px !important;

      cursor: pointer;
      padding: .5rem .75rem;
      border: 1px solid #edf0f6;
      border-radius: var(--border-radius-component);
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(300px);
  }
}



// dùng class custom để css cho những popup khác, ví dụ thêm 1 class confirm-abc để css riêng
//@media (max-width: 1366px) {
//  .confirm-popup {
//    height: 15.7rem;
//    &__group-btn {
//      margin-top: 1.5rem;
//    }
//  }
//}

.setting-2fa-modal {
  &_confirm {
    width: 480px;
    height: 284px;
  }

  &_txt {
    margin-top: 24px;
  }
}