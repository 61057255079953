.tab_list {
    height: 4rem;
    .tab {
        background: var(--white-color);
        color: var(--dismiss-color);
        width: 15.625rem;
        border-radius: var(--border-radius-component) var(--border-radius-component) 0 0;

        span {
            display: flex;
            flex-direction: row;
            font-family: var(--main-font-family);
            font-size: var(--h3-font-size);
            text-transform: initial;

            svg {
                margin: 0 8px !important;
            }
        }
        &.selected {
            background: linear-gradient(0, rgba(43, 184, 169, 0.12) 0%, rgba(43, 184, 169, 0) 100%);
            font-weight: 600;
            color: var(--color-tilte-up2022-7);
        }
    }
}

.detail {
    text-align: right;
    .case, .edit {
        text-align: left;

        .gen {
            display: flex;
            align-items: center;
            height: 2.625rem;
            margin-bottom: .5rem;
            svg {
                margin-right: 2rem;
                width: 1.25rem;
                height: 1.25rem;
            }
        }
    }
    .edit {
        .gen {
            svg {
                margin-top: .375rem;
            }
            .ntd {
                width: 20rem;
                margin-top: -.75rem;
                position: relative;
            }
            input {
                border: none;
                border-bottom: 1px solid var(--bg-input);
                width: 100%;
                height: 2rem;
                //margin: -.4rem 0 0 0;
                font-size: 15px;
            }
        }
        .dob {
            position: relative;
            .icon {
                position: absolute;
                right: -22px;
                cursor: pointer;
            }
        }
        .gender {
            padding-bottom: 3.25rem;
            .grp_gender {
                display: flex;
                .item_gender {
                    display: flex;
                    align-items: center;
                    margin: .5rem 3.25rem 0 0;
                    position: relative;
                    input:before {
                        content: '';
                        width: 1.25rem;
                        height: 1.25rem;
                        position: absolute;
                        border-radius: 50%;
                        top: 0.375rem;
                        left: -.1875rem;
                        border: 1px solid var(--bg-input);
                        background: var(--white-color);
                    }
                    input[type="radio"]:checked:before {
                        border: 1px solid var(--bg-checked-radio);
                    }
                    input[type="radio"]:checked:after{
                        content: '';
                        width: .75rem;
                        height: .75rem;
                        position: absolute;
                        border-radius: 50%;
                        top: 0.6rem;
                        left: 1px;
                        background: var(--bg-checked-radio);
                    }
                    label {
                        margin: 0 0 0 1rem;
                        cursor: pointer;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 150%;
                    }
                }
            }
        }

    }
}

.update, .p_update {
    cursor: pointer;
    color: rgba(0, 82, 255, 1);
    background: white;
    border: none;

    font-weight: 400;
    font-size: .9375rem;
    line-height: 120%;

    position: absolute;
    right: 1.75rem;
}
.update.disabled, .p_update.disabled {
    cursor: not-allowed;
    color: rgba(0, 82, 255, 0.5);
    display: none;
}

.change {
    text-align: right;
    .wire {
        text-align: left;
        display: flex;
        img {
            width: 1.75rem;
            margin-top: -.5rem;
        }
        .para {
            margin-left: .6rem;
            width: 20rem;
            span {
                color: #32536A;
            }
        }
    }
    .in_hide {
        text-align: left;
        margin-top: 1.375rem;
        .grp {
            display: flex;
            margin-top: 1rem;
            height: 2.625rem;
            align-items: flex-end;
            .lock {
                display: flex;
                .icon {
                    margin: .25rem 1.25rem 0 0;
                }
                span {
                    margin-top: .8rem;
                }
            }
            .press {
                position: relative;
                width: 20.5rem;
                input {
                    width: 100%;
                    height: 2rem;
                    border: none;
                    border-bottom: 1px solid var(--background-border);
                    font-size: 15px;
                    padding: 0 2.5rem 0 0;
                }
                .toggle_eye {
                    cursor: pointer;
                    position: absolute;
                    right: 12px;
                    top: 6px;
                }
            }
        }
    }
}
.old_version {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 29.25rem;
    text-align: center;
    overflow: hidden;
    padding: 8px 16px;
    background: linear-gradient(268.72deg, #3CD6B7 0.38%, #4146E9 78.02%, #8931D3 189.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50px; 
        padding: 2px; 
        background: linear-gradient(268.72deg, #3CD6B7 0.38%, #4146E9 78.02%, #8931D3 189.47%);
        -webkit-mask: 
            linear-gradient(#fff 0 0) content-box, 
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
    }
}

.action {
    position: fixed;
    bottom: 0;
    right: 0;
    background: var(--white-color);
    width: 31.25rem;
    height: 4rem;
    text-align: end;

    hr {
        border: none;
        border-top: 1px solid var(--background-border);
    }

    button {
        font-size: 15px;
        line-height: 140%;
        margin-top: .875rem;

        cursor: pointer;
        padding: .5rem .75rem;
        border: 1px solid #edf0f6;
        border-radius: var(--border-radius-component);
    }
}
.dismiss {
    font-weight: 400;
    color: black;
    background: var(--white-color);
    margin-right: .75rem;
    &:hover {
        border: 1px solid var(--color-package-up2022-7);
    }
}
.save {
    font-weight: 600;
    color: var(--white-color);
    background: var(--bg-checked-radio);
    margin-right: 1.5rem;
    background: var(--color-package-up2022-7);
    &:hover {
        background: var(--color-hover-package-up2022-7);
        color: var(--white-color);
    }
}

.panel {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #7A92A5;
    width: 8rem;
    padding-bottom: 0;
}

.phone {
  color: var(--dismiss-color);
  background: white;
}

.panel ~ p {
    font-size: 14px;

    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
    max-width: 15rem;
}

.errors {
    color: var(--plum-color);
    font-weight: 400;
    font-size: .75rem;
    line-height: 140%;
    position: absolute;
    bottom: -1.2rem;
    left: 0;
    z-index: 99;
}

//label {
//    cursor: pointer;
//}