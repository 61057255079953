.tab-notifacation {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    &__body {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__tab {
        min-width: 97px;
        height: 36px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &--active {
            background: #EFF3FB;
            border-radius: 60px;
            padding: 8px 16px;
            font-weight: 600 !important;
            font-size: 14px !important;
        }
    }

    &__total {
        height: 19px;
        padding: 0.5px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FF424F;
        border: 1px solid #FFFFFF;
        border-radius: 9px;
        margin-left: 5px;
    }
}