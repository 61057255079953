.content-language {
  width: fit-content;
  .item-language {
    cursor: pointer;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #1e9a98;
    }

    &[ data-active = true ] {
      color: #1e9a98;
    }
  }
}
.tag-content{
  border-radius: 4px;
  border: 1px solid #1162FF;

  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  gap: 4px;
}
.content-editable__tag {
  width: fit-content;
  min-width: 200px;
  &-item {
    cursor: pointer;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: var(--Gray-800, linear-gradient(0deg, rgba(244, 247, 252, 0.98) 0%, rgba(244, 247, 252, 0.98) 100%), #00081D);
    }

    &[ data-active = true ] {
      color: #1e9a98;
    }
  }
}

.header-language__title-icontrans{
  path{
    fill: #fff;
  }
}