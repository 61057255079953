.content {
    background: radial-gradient(267.18% 229.62% at 50% -3.26%, #86E9D5 0%, #5ED8C8 3.65%, #3AC9BC 25.87%, #3391D4 48.44%, #307BDD 100%);
    height: 1000px;
    padding: 20px;

    .logo  {
        display: flex;
        margin-bottom: 20px;
        img {
            margin: 0 auto;
        }
    }
    .title {
        font-size: 14px;
        font-weight: 600;
        color: white;
        text-align: center;
    }
    .subtitle {
        font-size: 12px;
        color: white;
        text-align: center;
    }
    .app_wapper {
        margin-top: 20px;

        .app {
            margin: 10px auto;
            display: flex;
            justify-content: center;
        }
    }
}