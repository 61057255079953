@import "../../Component/mixin/index";

.package-content{
  position: relative;
  width: auto;
  height: 36px;
  padding: 2px 0 2px 12px;
  margin-right: 24px;

  border-radius: 6px;
  display: flex;
  align-items: center;

  cursor: pointer;
  .package-crown{
    margin-right: 8px;
  }
  .package-info{
    padding: 2px 0;
  }
}

.package-fullname, .package-license_name{
  margin-bottom: 5px;
}
.package-expiration_date{
  margin-bottom: 15px;
}
.card-content__package {
  padding: 19px 0 20px 24px;
  box-shadow: none !important;
  position: relative;
  width: 391px;
  height: 200px;

  .growth-card {
    height: 161px;

    &__left {
      &-title {
        font-style: italic;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #27272A;
        @include desktop_min{
          font-size: 14px;
        }
      }

      &-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #27272A;
        @include desktop_min{
          font-size: 12px;
        }
      }

      &-amount {
        font-weight: 400;
        font-size: 26px;
        line-height: 36px;
        color: #1E9A98;
        @include desktop_min{
          font-size: 20px;
        }
      }

      &__items {
        margin-top: 15px;

        .label-support {
          p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #808089;
            @include desktop_min{
              font-size: 12px;
            }
          }

          margin-bottom: 5px;
        }

        &-btn {
          button {
            text-transform: none;

          }

          &-upgrade {
            border: 1px solid #2BB8A9;
            border-radius: 4px;
            margin-right: 12px;
            padding: 5px 21px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #2BB8A9;

            &:hover {
              background-color: #2BB8A9;
              color: white;
            }
          }

          &-extend {
            background: #2BB8A9;
            border-radius: 4px;
            padding: 5px 27px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #FFFFFF;
            border: 1px solid #2BB8A9;

            &:hover {
              text-decoration: none;
              background-color: white;
              color: #2BB8A9;
              border: 1px solid #2BB8A9;
            }
          }
        }
      }
    }
  }

  .card-reward {
    position: absolute;
    bottom: -0.4rem;
    right: 9px;

    @include desktop_min {
      display: none;
    }

    @include tablet {
      display: none;
    }
  }
}

@media screen and (max-width:1680px) {
  .card-content {
    .growth-card {
      height: 182px;
    }
  }
  
}
@media screen and (max-width:1366px) {
  .card-content {
    .growth-card {
      height: 204px;
      .growth-card__left__items-btn-upgrade {
        border: 1px solid #2BB8A9;
        border-radius: 4px;
        margin-right: 12px;
        padding: 5px 21px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #2BB8A9;
        margin-bottom: 10px;
    }
    }
  }
  
}