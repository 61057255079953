.header-notification {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;

    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        color: #00081D;
    }

    &__read {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        color: #1A94FF;
        cursor: pointer;

        svg {
            margin-right: 5px;
        }
    }
}