.notice-component-popover{
    position: relative;
    top: calc(100% - 26px) !important;
    .notification-bell-total{
        position: absolute;
        top: -5px;
        left: 12px;
        background: #FF424F;
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        padding: 0 4px;
        height: 16px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .notification {
        width: 452px;
        max-height: 64vh;

        display: flex;
        flex-direction: column;

        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__title {
            font-weight: 600;
            font-size: 16px;
            color: #00081D;
        }

        &__read {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 13px;
            color: #1A94FF;

            svg{
                margin-right: 5px;
            }
        }
    }
}
